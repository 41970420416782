function cached(resource) {
    let get = resource.get
    resource._cache = null
    resource.get = function(params) {
        if (!resource._cache) {
            resource._cache = get(params)
        }
        return resource._cache
    }
    return resource
}

export default {
    Login() {
        return this.$resource(this.apiUrl + 'users/login')
    },
    Register() {
        return this.$resource(this.apiUrl + 'users/register')
    },
    Context() {
        return this.$resource(this.apiUrl + 'users/context')
    },
    Logout() {
        return this.$resource(this.apiUrl + 'users/logout')
    },
    Forgot() {
        return this.$resource(this.apiUrl + 'users/forgot')
    },
    Reset() {
        return this.$resource(this.apiUrl + 'users/reset')
    },
    Password() {
        return this.$resource(this.apiUrl + 'users/password')
    },
    WikiArticle() {
        return this.$resource(this.apiUrl + 'wiki/article/{slug}')
    },
    Plan() {
        return this.$resource(this.apiUrl + 'plan')
    },
    Data() {
        return cached(this.$resource(this.apiUrl + 'dash/data'));
    },
    Stats() {
        return this.$resource(this.apiUrl + 'dash/stats')
    },
    Token() {
        return this.$resource(this.apiUrl + 'users/token')
    },
    PromoCode() {
        return this.$resource(this.apiUrl + 'users/promocode')
    },
    Transaction() {
        return this.$resource(this.apiUrl + 'users/transaction')
    },
    Payment() {
        return this.$resource(this.apiUrl + 'users/payment')
    },
    PaymentSystem() {
        return this.$resource(this.apiUrl + 'users/payment/system')
    },
    Invoice() {
        return this.$resource(this.apiUrl + 'users/invoice/{id}')
    },
    Review(){
        return this.$resource('api/users/review')
    }
}
