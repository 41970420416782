var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        !_vm.loaded
          ? _c("Spinner")
          : _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("h1", { staticClass: "mb-0 fw-bold" }, [_vm._v("Tokens")]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-6 text-muted" }, [
                  _vm._v(
                    "\n                    Create tokens and use to access your API"
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _vm.$root.context.id
                    ? _c("a", { attrs: { href: `${_vm.prefix}/docs` } }, [
                        _vm._v(_vm._s(_vm.prefix) + "/docs"),
                      ])
                    : _vm._e(),
                  _vm._v(" (Cloudflare, Swagger)"),
                  _c("br"),
                  _vm._v(" "),
                  _vm.$root.context.id
                    ? _c("a", { attrs: { href: `${_vm.prefix}/redoc` } }, [
                        _vm._v(_vm._s(_vm.prefix) + "/redoc"),
                      ])
                    : _vm._e(),
                  _vm._v(" (Cloudflare, Redoc)"),
                  _c("br"),
                  _vm._v(" "),
                  _vm.$root.context.id
                    ? _c(
                        "a",
                        { attrs: { href: "https://api.instagrapi.com/docs" } },
                        [_vm._v("https://api.instagrapi.com/docs")]
                      )
                    : _vm._e(),
                  _vm._v(" (without Cloudflare, Swagger)"),
                  _c("br"),
                  _vm._v(" "),
                  _vm.$root.context.id
                    ? _c(
                        "a",
                        { attrs: { href: "https://api.instagrapi.com/redoc" } },
                        [_vm._v("https://api.instagrapi.com/redoc")]
                      )
                    : _vm._e(),
                  _vm._v(" (without Cloudflare, Redoc)\n                "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c("vuetable", {
                      ref: "vuetable",
                      staticClass: "ui celled table unstackable table-scroll",
                      attrs: {
                        "api-mode": false,
                        data: _vm.tableData,
                        "wrapper-class": "vuetable-wrapper ui basic segment",
                        "table-wrapper": ".vuetable-wrapper",
                        fields: _vm.fields,
                      },
                      on: { "vuetable:pagination-data": _vm.onPaginationData },
                      scopedSlots: _vm._u([
                        {
                          key: "actions",
                          fn: function (props) {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mb-1 btn-xs",
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(props.rowData)
                                    },
                                  },
                                },
                                [_vm._v("Edit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger mb-1 btn-xs",
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(props.rowData)
                                    },
                                  },
                                },
                                [_vm._v("Delete")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mb-1 btn-xs",
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.create },
                  },
                  [_vm._v("Create a token")]
                ),
                _vm._v(" "),
                _vm.$root.context.id
                  ? _c("p", [
                      _c("br"),
                      _vm._v(
                        '\n                    To use the API you need to pass "Access Key" in headers:'
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("pre", [
                        _c("code", { staticClass: "highlight sh" }, [
                          _vm._v(
                            "\n                        curl -X GET " +
                              _vm._s(_vm.prefix) +
                              "/a2/user?username=sun \\\n                          -H 'accept: application/json' \\\n                          -H 'x-access-key: " +
                              _vm._s(_vm.firstAccessKey) +
                              "'\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v("\n                    or as a GET-parameter:"),
                      _c("br"),
                      _vm._v(" "),
                      _c("pre", [
                        _c("code", { staticClass: "highlight bash" }, [
                          _vm._v(
                            "\n                        curl -X GET " +
                              _vm._s(_vm.prefix) +
                              "/a2/user?username=sun&access_key=" +
                              _vm._s(_vm.firstAccessKey) +
                              " \\\n                          -H 'accept: application/json'\n                    "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }