var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "pt-12 pt-md-14 mt-n11 bg-primary" }),
    _vm._v(" "),
    _c("section", { staticClass: "position-relative pt-12 pt-md-14 mt-n11" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row align-items-center text-center text-md-start" },
          [
            _c("div", { staticClass: "col-12 col-md-12" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("dl", [
                _c("dt", { staticClass: "fs-lg text-primary-desat mt-2" }, [
                  _vm._v("Do you have a plan to make over a million requests?"),
                ]),
                _vm._v(" "),
                _c("dd", { staticClass: "fs-lg text-muted p-3" }, [
                  _vm._v(
                    "\n                            Yes, you can write to us by "
                  ),
                  _c(
                    "a",
                    { attrs: { href: `mailto:${_vm.$root.siteEmail}` } },
                    [_vm._v("our mail")]
                  ),
                  _vm._v("\n                            or "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: `https://t.me/${_vm.$root.siteTgSupport}`,
                      },
                    },
                    [_vm._v("telegram")]
                  ),
                ]),
                _vm._v(" "),
                _c("dt", { staticClass: "fs-lg text-primary-desat mt-2" }, [
                  _vm._v("Will my funds expire at the end of the month?"),
                ]),
                _vm._v(" "),
                _c("dd", { staticClass: "fs-lg text-muted p-3" }, [
                  _vm._v(
                    "\n                            No, we only charge for successful requests. if you stop making requests your balance will remain unchanged\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("dt", { staticClass: "fs-lg text-primary-desat mt-2" }, [
                  _vm._v("What requests do you charge for?"),
                ]),
                _vm._v(" "),
                _c("dd", { staticClass: "fs-lg text-muted p-3" }, [
                  _vm._v(
                    "\n                            We charge for any successful response (such as 200, 403, 404). For 50x errors we do not charge\n                        "
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-1 fw-bold" }, [
      _c("div", [_vm._v("FAQ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }